import { Injectable } from '@angular/core';
import { HttpConfigService } from '../../core/httpConfig/httpConfigService';
import { BehaviorSubject } from 'rxjs';
import { ICartDetails } from '../../appInterfaces/ICartDetails';
import { BasicInfoService } from '../../appServices/basic-info/basic-info.service';
import { IBasicInfo } from '../../appInterfaces/IBasicInfo';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  api: string;
  loading = new BehaviorSubject(false);
  cart = new BehaviorSubject<any>(null);
  private cartUpdated = new BehaviorSubject<boolean>(false);
  cartUpdated$ = this.cartUpdated.asObservable();
  private basicInfo: IBasicInfo | null = null; // Store basicInfo

  constructor(
    private apiConfig: HttpConfigService,
    private basicInfoService: BasicInfoService // Inject BasicInfoService
  ) {
    this.api = this.apiConfig.getBaseUrl + 'cart-details';

    // Subscribe to BasicInfoService to get the business type
    this.basicInfoService.basicInfo.subscribe((res) => {
      this.basicInfo = res;
    });
  }

  // Fetch the cart details
  getCart(): void {
    this.loading.next(true);
    this.apiConfig.get(this.api, { withCredentials: true }).subscribe(
      (res: any[]) => {
        this.loading.next(false);
        res.forEach((item) => {
          this.calculatePerItemPrice(item);
        });
        this.cart.next(res);
      },
      (err) => {
        console.error('Error fetching cart:', err);
        this.loading.next(false);
        this.cart.next(null);
      }
    );
  }

  // Clear the cart
  clearCart(): void {
    this.cart.next(null);
    this.cartUpdated.next(true); // Emit event to notify cart update
  }

  // Remove an item from the cart
  removeItem(item: ICartDetails): void {
    this.loading.next(true);
    this.apiConfig.delete(this.api + '/' + item.id, { withCredentials: true }).subscribe(
      () => {
        this.getCart();
      },
      (err) => {
        console.error('Error removing item:', err);
        this.loading.next(false);
      }
    );
  }

  // Add an item to the cart
  addToCart(item: ICartDetails): void {
    this.loading.next(true);
    this.apiConfig.post(this.api, item, { withCredentials: true }).subscribe(
      () => {
        this.getCart();
      },
      (err) => {
        console.error('Error adding item:', err);
        this.loading.next(false);
      }
    );
  }

  // Get cart details by ID
  getCartDetailById(cartId: number): ICartDetails | null {
    if (!this.cart.value) {
      return null;
    }

    for (const item of this.cart.value) {
      if (item.id === cartId) {
        return { ...item };
      }
    }
    return null;
  }

  // Calculate price for each cart item
  calculatePerItemPrice(item: any): void {
    let price: number;

    // Check if the business type is cannabis and use tierPrice directly
    if (this.basicInfo?.businessType === 'cannabis' && item.tierPrice) {
      price = item.tierPrice; // Use tierPrice directly without multiplying by quantity
    } else {
      // Default logic for non-cannabis businesses or no tierPrice
      price = item.productModel.basePrice;

      // Apply discount if applicable
      if (item.productModel.discount) {
        price = item.productModel.discount.discount;
      }

      // Add variants and add-ons prices
      item.cartDetailsVariantModelList.forEach((variant: any) => {
        price += variant.price;
      });
      item.cartDetailsAddOnModelList.forEach((addOn: any) => {
        price += addOn.addOnModel.addOnPrice;
      });

      // Multiply by quantity for non-cannabis businesses
      price *= item.quantity;
    }

    // Update the item's price
    item.price = price;
  }

  // Calculate the total price of the cart
  totalPriceOfCart(): number {
    let total = 0;

    if (this.cart.value) {
      this.cart.value.forEach((element: any) => {
        // Exclude items with loyalty points spent
        if (element?.loyaltyPointsSpent > 0) {
          total += 0;
        } else {
          total += element.price; // Use the calculated price directly
        }
      });
    }

    return total;
  }

  // Calculate total loyalty points spent in the cart
  totalLoyaltyPointsSpent(): number {
    let totalPointsSpent = 0;

    if (this.cart.value) {
      this.cart.value.forEach((element: any) => {
        if (element?.loyaltyPointsSpent > 0) {
          totalPointsSpent += element.loyaltyPointsSpent;
        }
      });
    }

    return totalPointsSpent;
  }
}
